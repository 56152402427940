import React from "react";
import "../components/Styles/contact.css"

export default function Contact() {
    return (
        <div className="contact-div">
            <div className="videophoto-header">
                <p>CONTACT</p>
            </div>

            <div className="contact-body">
                <h2>BEST WAY TO REACH US</h2>
              
                    <p className="type">EMAIL
                    <p className="input">BETTER@ROMPALA.BIZ</p></p>
                    
                    <p className="type">PHONE
                    
                    <p className="input">216.352.8658</p></p>
                
            </div>
        </div>
    )
}